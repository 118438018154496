import React from 'react'
import styled from 'styled-components/macro'

import { RiMailSendFill } from 'react-icons/ri'

const Header = () => {
  const sendEmail = () => {
    window.location.href =
      "mailto:maxime.breham@gmail.com?subject=Demande d'informations"
  }
  return (
    <HeaderContainer>
      <Menu>
        <Contact onClick={sendEmail}>
          <RiMailSendFill className='icon-send' />
          <span>Contact</span>
        </Contact>
      </Menu>
    </HeaderContainer>
  )
}

const HeaderContainer = styled.nav`
  height: 5vh;
  display: flex;
  justify-content: center;
`
const Menu = styled.div`
  display: flex;
  align-items: center;
  max-width: 1400px;
  width: 100%;
  justify-content: flex-end;
`
const Contact = styled.a`
  display: flex;
  align-items: center;
  font-family: ${({ theme }) => theme.fonts.normal};
  color: ${({ theme }) => theme.colors.secondary};
  text-transform: uppercase;
  font-weight: bold;
  font-size: 18px;

  z-index: 10;
  padding-right: 10px;

  @media screen and (max-width: 420px) {
    font-size: 12px;
  }

  & .icon-send {
    margin-right: 5px;
    font-size: 22px;
    margin-bottom: 5px;
    @media screen and (max-width: 420px) {
      font-size: 16px;
    }
  }
`

export default Header
