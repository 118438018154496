import React, { useEffect, useState } from 'react'
import styled from 'styled-components/macro'
import { IoArrowUpCircle } from 'react-icons/io5'
import useNavigation from '../Hooks/useNavigation'

const NavBar = () => {
  const { scrollTo, targetNext, targetPrev } = useNavigation()

  const [namePrev, setNamePrev] = useState('empty')
  const [nameNext, setNameNext] = useState('experiences')
  const [animateChange, setAnimateChange] = useState(0.3)

  useEffect(() => {
    const setNameById = target => {
      switch (target) {
        case 'experiences':
          return 'experiences'
        case 'projets':
          return 'projets'
        case 'skills':
          return 'compétences'
        case 'about':
          return 'A propos'
        case 'empty':
          return ''
        default:
          return 'top'
      }
    }

    if (animateChange === 1) setAnimateChange(0.3)

    const timeoutAnimate = setTimeout(function () {
      setAnimateChange(1)
      setNamePrev(setNameById(targetPrev))
      setNameNext(setNameById(targetNext))
    }, 500)

    return () => clearTimeout(timeoutAnimate)
  }, [targetNext, targetPrev])

  return (
    <NavBarContainer>
      <NavBarItems>
        <PrevMenu disabled={targetPrev === 'empty'}>
          <IoArrowUpCircle
            className='icon'
            onClick={
              targetPrev !== 'empty' ? e => scrollTo(e, targetPrev) : null
            }
          />

          <Text key='textPrev'>{namePrev}</Text>
        </PrevMenu>
        <NextMenu disabled={targetNext === 'empty'}>
          <IoArrowUpCircle
            className='icon'
            onClick={
              targetNext !== 'empty' ? e => scrollTo(e, targetNext) : null
            }
          />
          <Text key='textNext' duration={0.5}>
            {nameNext}
          </Text>
        </NextMenu>
        <BarContainer>
          <Bar />
        </BarContainer>
      </NavBarItems>
    </NavBarContainer>
  )
}

const NavBarContainer = styled.div`
  position: fixed;
  bottom: 0;
  font-family: ${({ theme }) => theme.fonts.normal};
  text-transform: uppercase;
  color: ${({ theme }) => theme.colors.secondary};
  height: 60px;
  width: 100%;
  z-index: 100;

  @media screen and (max-width: 768px) {
    display: none;
  }
`

const NavBarItems = styled.div`
  max-width: 1400px;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
  display: flex;
`
const PrevMenu = styled.a`
  display: flex;
  align-items: center;
  padding-left: 10px;

  z-index: 100;
  & .icon {
    opacity: ${({ disabled }) => (disabled ? 0.5 : 1)};
    font-size: 20px;
    margin-bottom: 3px;
  }
`
const NextMenu = styled.a`
  align-items: center;
  display: flex;

  & .icon {
    transform: rotate(180deg);
    opacity: ${({ disabled }) => (disabled ? 0.5 : 1)};
    font-size: 20px;
    margin-bottom: 3px;
  }
`
const Text = styled.div`
  font-size: 12px;
  width: max-content;
  padding-left: 5px;
  padding-right: 10px;
`
const BarContainer = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  padding-right: 10px;
`
const Bar = styled.hr`
  width: 100%;
  opacity: 0.6;
`

export default NavBar
