import React, { useState } from 'react'

const NavigationContext = React.createContext({})

const NavigationProvider = ({ children }) => {
  const [expSelected, setExpSelected] = useState(false)
  const [projetsSelected, setProjetsSelected] = useState(false)
  const [skillsSelected, setSkillsSelected] = useState(false)
  const [aboutSelected, setAboutSelected] = useState(false)
  const [targetPrev, setTargetPrev] = useState('empty')
  const [targetNext, setTargetNext] = useState('experiences')

  return (
    <NavigationContext.Provider
      value={{
        exp: [expSelected, setExpSelected],
        projet: [projetsSelected, setProjetsSelected],
        skills: [skillsSelected, setSkillsSelected],
        about: [aboutSelected, setAboutSelected],
        prev: [targetPrev, setTargetPrev],
        next: [targetNext, setTargetNext]
      }}
    >
      {children}
    </NavigationContext.Provider>
  )
}

export { NavigationContext, NavigationProvider }
