import useNavigation from '../Hooks/useNavigation'
import styled from 'styled-components/macro'
import React from 'react'

const Hero = () => {
  const {
    scrollTo,
    expSelected,
    projetsSelected,
    skillsSelected,
    aboutSelected
  } = useNavigation()

  return (
    <HeroContainer id='hero'>
      <Title>
        <Prenom>Maxime</Prenom>
        <Nom>Breham</Nom>
        <DevContainer>
          <Expo>Web</Expo>
          <Big>Dev.</Big>
        </DevContainer>
      </Title>
      <NavRow>
        <Menu>
          <LinkMenu
            onClick={e => scrollTo(e, 'experiences')}
            className={expSelected ? 'selected' : ''}
            href='#experiences'
          >
            Experiences
          </LinkMenu>
          <LinkMenu
            onClick={e => scrollTo(e, 'projets')}
            className={`big ${projetsSelected ? 'selected' : ''}`}
            href='#projets'
          >
            Projets
          </LinkMenu>
          <LinkMenu
            onClick={e => scrollTo(e, 'skills')}
            name='skills'
            className={`medium ${skillsSelected ? 'selected' : ''}`}
            href='#skills'
          >
            Compétences
          </LinkMenu>
          <LinkMenu
            onClick={e => scrollTo(e, 'about')}
            name='about'
            className={`small ${aboutSelected ? 'selected' : ''}`}
            href='#about'
          >
            A propos
          </LinkMenu>
        </Menu>
      </NavRow>
    </HeroContainer>
  )
}

const HeroContainer = styled.section`
  max-width: 1400px;
  width: 100%;
  margin: 0 auto;
  z-index: 100;
  height: 95vh;

  @media screen and (max-width: 1100px) {
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 95vh;
  }
`
const Title = styled.div`
  display: flex;
  flex-direction: column;
  color: ${({ theme }) => theme.colors.secondary};
  text-transform: uppercase;
  line-height: 110px;
  width: 50%;
  padding-top: 60px;

  @media screen and (max-width: 1100px) {
    margin-bottom: 200px;
    margin: 200px auto 200px auto;
    width: 50%;
  }

  @media screen and (max-width: 800px) {
    margin: 0px auto 200px auto;
    width: 70%;
  }

  @media screen and (max-width: 420px) {
    line-height: 74px;
    margin: 0px auto 90px auto;
  }

  @media screen and (max-width: 375px) {
    width: 80%;
  }

  @media screen and (max-width: 320px) {
    width: 90%;
    margin: 0px auto 30px auto;
  }
`
const Prenom = styled.h1`
  font-family: ${({ theme }) => theme.fonts.normal};
  font-size: 80px;
  font-weight: bold;
  margin: 0;
  letter-spacing: -3px;

  @media screen and (max-width: 420px) {
    font-size: 60px;
  }

  @media screen and (max-width: 320px) {
    font-size: 50px;
  }
`
const Nom = styled.h2`
  font-size: 127px;
  font-weight: normal;
  font-family: ${({ theme }) => theme.fonts.normal};
  letter-spacing: -12px;

  @media screen and (max-width: 420px) {
    font-size: 80px;
  }

  @media screen and (max-width: 320px) {
    font-size: 70px;
  }
`
const DevContainer = styled.div`
  display: flex;
  max-width: 522px;
  justify-content: flex-end;
  align-items: flex-start;
`
const Expo = styled.div`
  color: ${({ theme }) => theme.colors.optional};
  display: inline-flex;
  flex-direction: column;
  font-family: ${({ theme }) => theme.fonts.normal};
  font-size: 16px;
  font-weight: bold;
  line-height: 20px;

  @media screen and (max-width: 420px) {
    font-size: 12px;
    line-height: 32px;
  }
`
const Big = styled.div`
  font-family: ${({ theme }) => theme.fonts.big};
  text-transform: uppercase;
  font-size: 148px;
  letter-spacing: -2px;
  opacity: 0.4;

  @media screen and (max-width: 420px) {
    font-size: 90px;
  }

  @media screen and (max-width: 320px) {
    font-size: 70px;
  }
`

const NavRow = styled.div`
  display: flex;
  justify-content: flex-end;
  @media screen and (max-width: 1100px) {
    margin: 0 auto;

    justify-content: flex-start;
  }
`

const Menu = styled.div`
  display: flex;
  flex-direction: column;
  font-family: ${({ theme }) => theme.fonts.normal};
`
const LinkMenu = styled.a`
  display: block;
  color: ${({ theme }) => theme.colors.secondary};
  font-size: 41px;
  font-weight: bold;
  text-transform: uppercase;
  line-height: 40px;

  z-index: 15;
  transition-property: font-size, color, line-height;
  transition-duration: 0.6s;
  transition-timing-function: ease-in-out;
  width: 700px;

  @media screen and (max-width: 420px) {
    font-size: 30px;
    width: 330px;
    line-height: 30px;
  }

  @media screen and (max-width: 320px) {
    font-size: 25px;
    width: 300px;
    line-height: 25px;
  }

  &.big {
    font-size: 30px;
    @media screen and (max-width: 420px) {
      font-size: 21px;
    }

    @media screen and (max-width: 320px) {
      font-size: 17px;
    }
  }

  &.medium {
    font-size: 25px;
    @media screen and (max-width: 420px) {
      font-size: 18px;
    }

    @media screen and (max-width: 320px) {
      font-size: 16px;
    }
  }

  &.small {
    font-size: 18px;

    @media screen and (max-width: 320px) {
      font-size: 14px;
    }
  }

  &:before {
    content: '< ';
    opacity: 0;
    transition: opacity 0.6s ease-in-out;
    color: ${({ theme }) => theme.colors.optional};
    font-size: 70px;

    @media screen and (max-width: 420px) {
      font-size: 30px;
    }

    @media screen and (max-width: 320px) {
      font-size: 25px;
    }
  }
  &:after {
    content: ' />';
    opacity: 0;
    transition: opacity 0.4s ease-in-out;
    color: ${({ theme }) => theme.colors.optional};
    font-size: 70px;

    @media screen and (max-width: 420px) {
      font-size: 30px;
    }

    @media screen and (max-width: 320px) {
      font-size: 25px;
    }
  }

  &:hover,
  &.selected {
    font-size: 60px;
    color: ${({ theme }) => theme.colors.optional};
    line-height: 70px;

    @media screen and (max-width: 420px) {
      font-size: 30px;
      line-height: 50px;
    }

    @media screen and (max-width: 320px) {
      font-size: 25px;
      line-height: 40px;
    }

    &:before {
      opacity: 1;
    }
    &:after {
      opacity: 1;
    }
  }
`

export default Hero
