import { useContext } from 'react'
import { NavigationContext } from '../Contexts/NavigationContext'
import { scroller, animateScroll as scroll } from 'react-scroll'

const useNavigation = () => {
  const { exp, projet, skills, about, prev, next } = useContext(
    NavigationContext
  )

  const [expSelected, setExpSelected] = exp
  const [projetsSelected, setProjetsSelected] = projet
  const [skillsSelected, setSkillsSelected] = skills
  const [aboutSelected, setAboutSelected] = about
  const [targetPrev, setTargetPrev] = prev
  const [targetNext, setTargetNext] = next

  const selectSection = name => {
    setExpSelected(false)
    setProjetsSelected(false)
    setSkillsSelected(false)
    setAboutSelected(false)

    switch (name) {
      case 'experiences':
        setTargetPrev('top')
        setTargetNext('projets')
        setExpSelected(true)
        break
      case 'projets':
        setProjetsSelected(true)
        setTargetPrev('experiences')
        setTargetNext('skills')
        break

      case 'skills':
        setSkillsSelected(true)
        setTargetPrev('projets')
        setTargetNext('about')
        break
      case 'about':
        setAboutSelected(true)
        setTargetPrev('skills')
        setTargetNext('top')
        break
      default:
        setTargetPrev('empty')
        setTargetNext('experiences')
    }
  }

  const scrollTo = (e, idToScroll) => {
    if (idToScroll === 'top') {
      scroll.scrollToTop()
    } else {
      scroller.scrollTo(idToScroll, {
        duration: 800,
        delay: 0,
        smooth: 'easeInOutQuart'
      })
    }

    selectSection(idToScroll)
  }

  return {
    expSelected,
    projetsSelected,
    skillsSelected,
    aboutSelected,
    scrollTo,
    targetPrev,
    targetNext
  }
}

export default useNavigation
