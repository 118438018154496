import React from 'react'
import styled from 'styled-components/macro'

const About = () => {
  return (
    <AboutContainer id='about'>
      <Title>A propos</Title>
      <Text>
        <p>
          Avec maintenant presque 11 ans d'expérience dans le domaine du WEB,
          j’ai suffisamment de recul et de connaissances qui me permettent de
          répondre à un grand nombre de demandes diverses et variées .
        </p>
        <p>
          Que ce soit la création d’un site vitrine “one page”, une application
          plus complexe, la refonte d’un site existant ou juste l’intégration
          d’une maquette, tout est faisable et pour “presque” tout les budgets.
        </p>
        <p>
          En ce qui concerne ma manière de travailler, j’aime être proche de mes
          clients et de leurs besoins. C’est pour cela que j’accorde pas mal de
          temps à la compréhension de chaque projet et que je commence toutes
          mes prestations par un cahier des charges, plus ou moins évoluer en
          fonction de chacun, qui a pour but d’éviter toutes ambiguïtés ou
          incompréhensions par la suite. Une fois ce dernier validé, si besoin
          je présente une maquette et enfin si toutes ces étapes sont validées ,
          alors je commence le projet.
        </p>
        <p>
          De nos jours, trop de freelancers négligent ces étapes afin de gagner
          du temps, mais selon moi c’est le meilleur moyen d’estimer au mieux
          les coûts relatifs au développement afin de démarrer une collaboration
          dans les meilleures conditions possibles.
        </p>
      </Text>
    </AboutContainer>
  )
}

const AboutContainer = styled.section`
  height: 100vh;
  max-width: 1400px;
  margin: 0 auto;
`
const Title = styled.h2`
  margin-top: 40px !important;
  margin-bottom: 40px !important;
  padding-left: 20px;
  font-family: ${({ theme }) => theme.fonts.big};
  font-size: 60px;
  color: ${({ theme }) => theme.colors.secondary};

  @media screen and (max-width: 768px) {
    font-size: 50px;
  }
  @media screen and (max-width: 420px) {
    font-size: 30px;
  }
`
const Text = styled.div`
  margin: 0 auto;
  max-width: 960px;
  font-family: ${({ theme }) => theme.fonts.normal};
  font-size: 16px;
  color: ${({ theme }) => theme.colors.secondary};
  text-size-adjust: initial;

  & p {
    font-size: 14px;
    margin: 20px !important;
    line-height: 30px;
    letter-spacing: 0px;

    @media screen and (max-width: 768px) {
      font-size: 16px;
      line-height: 30px;
    }
    @media screen and (max-width: 420px) {
      font-size: 12px;
      line-height: 22px;
    }
  }
`

export default About
