import Header from '../src/Components/Header'
import Hero from '../src/Components/Hero'
import Exp from '../src/Components/Exp'
import Project from '../src/Components/Projects'
import Skills from '../src/Components/Skills'
import './App.css'
import styled, { keyframes } from 'styled-components'
import NavBar from './Components/NavBar'
import { NavigationProvider } from '../src/Contexts/NavigationContext'
import DecoratorForNavigation from './Components/DecoratorForNavigation'
import About from './Components/About'
import AnimatedCursor from 'react-animated-cursor'

function App () {
  return (
    <div className='App'>
      <AnimatedCursor />
      <Page>
        <NavigationProvider>
          <DecoratorForNavigation>
            <Header />
            <Hero />
            <Exp />
            <Project />
            <Skills />
            <About />
            <NavBar />
          </DecoratorForNavigation>
        </NavigationProvider>
      </Page>
    </div>
  )
}

const grainAnimation = keyframes`
  0%,
  100% {
    transform: translate(0, 0);
  }
  10% {
    transform: translate(-5%, -10%);
  }
  20% {
    transform: translate(-15%, 5%);
  }
  30% {
    transform: translate(7%, -25%);
  }
  40% {
    transform: translate(-5%, 25%);
  }
  50% {
    transform: translate(-15%, 10%);
  }
  60% {
    transform: translate(15%, 0%);
  }
  70% {
    transform: translate(0%, 15%);
  }
  80% {
    transform: translate(3%, 35%);
  }
  90% {
    transform: translate(-10%, 10%);
  }
`

const Page = styled.div`
  background-color: ${({ theme }) => theme.colors.primary};
  height: 100%;
  z-index: 2;

  &:after {
    animation: ${grainAnimation} 8s steps(10) infinite;
    background-image: url('/img/grain.png');
    content: '';
    height: 300%;
    left: -50%;
    opacity: 0.3;
    position: fixed;
    top: -100%;
    width: 300%;
    z-index: 1;
  }
`

export default App
