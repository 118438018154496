import { createGlobalStyle } from 'styled-components'

export const Theme = {
  colors: {
    primary: '#0f0f10',
    secondary: '#AEAEAE',
    optional: '#93CFD5'
  },
  fonts: {
    big: 'Poiret One, cursive',
    normal: 'Spartan, sans-serif',
    mono: 'Ubuntu Mono, monospace'
  }
}

export const GlobalStyle = createGlobalStyle`



  * {
    margin: 0;
    padding: 0;
  
  }

  a{
    text-decoration:none;

  }

  /* Box sizing rules */
*,
*::before,
*::after {
  box-sizing: border-box;
}

/* Remove default padding */
ul[class],
ol[class] {
  padding: 0;
}

/* Remove default margin */
body,
h1,
h2,
h3,
h4,
p,
li,
figure,
figcaption,
blockquote,
dl,
dd {
  margin: 0 !important;
}

/* Set core body defaults */
body {
  min-height: 100vh;
  scroll-behavior: smooth;
  text-rendering: optimizeSpeed;
  line-height: 1.5;
}

/* Remove list styles on ul, ol elements with a class attribute */
ul[class],
ol[class] {
  list-style: none;
}

/* A elements that don't have a class get default styles */
a:not([class]) {
  text-decoration-skip-ink: auto;
}

/* Make images easier to work with */
img {
  max-width: 100%;
  display: block;
}


/* Inherit fonts for inputs and buttons */
input,
button,
textarea,
select {
  font: inherit;
}



  

`
