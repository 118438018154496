import React, { useRef, useLayoutEffect, useEffect, useState } from 'react'
import styled from 'styled-components/macro'
import data from '../projets.json'
import Masonry from 'react-masonry-css'
import { motion, useAnimation } from 'framer-motion'

const Projects = () => {
  const [CatSelected, setCatSelected] = useState('all')
  const originOffset = useRef({ top: 0, left: 0 })
  const controls = useAnimation()

  useEffect(() => {
    controls.start('visible')
  }, [CatSelected])

  const breakpointColumnsObj = {
    default: 4,
    1100: 3,
    700: 2,
    500: 1
  }

  return (
    <ProjetContainer id='projets'>
      <TitleContainer>
        <Title>Projets</Title>
      </TitleContainer>
      <PaginationProjet>
        <PageItem
          onClick={() => setCatSelected('all')}
          selected={CatSelected === 'all'}
          className={CatSelected === 'all' ? 'active' : ''}
        >
          Tous
        </PageItem>
        <PageItem
          onClick={() => setCatSelected('front')}
          selected={CatSelected === 'front'}
          className={CatSelected === 'front' ? 'active' : ''}
        >
          Front
        </PageItem>
        <PageItem
          onClick={() => setCatSelected('back')}
          selected={CatSelected === 'back'}
          className={CatSelected === 'back' ? 'active' : ''}
        >
          Back
        </PageItem>
        <PageItem
          onClick={() => setCatSelected('design')}
          selected={CatSelected === 'design'}
          className={CatSelected === 'design' ? 'active' : ''}
        >
          Design
        </PageItem>
      </PaginationProjet>
      <ListeProjets initial='hidden' animate={controls} variants={{}}>
        <Masonry
          breakpointCols={breakpointColumnsObj}
          className='my-masonry-grid'
          columnClassName='my-masonry-grid_column'
        >
          {data &&
            data.projets
              .filter(projet =>
                CatSelected !== 'all'
                  ? projet.skills.indexOf(CatSelected) !== -1
                  : true
              )
              .map((projet, index) => (
                <CardProjet
                  key={projet.id}
                  projet={projet}
                  index={index}
                  originIndex={3}
                  delayPerPixel={0.0005}
                  originOffset={originOffset}
                />
              ))}
        </Masonry>
      </ListeProjets>
    </ProjetContainer>
  )
}

const ProjetContainer = styled.section`
  width: 100%;
  max-width: 1400px;
  margin: 40px auto;
  z-index: 150;
  height: 100vh;

  @media screen and (max-width: 420px) {
    height: 100%;
  }
`
const TitleContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  padding-left: 20px;
`
const Title = styled.h2`
  display: flex;
  align-items: center;
  width: 100%;
  font-size: 60px;
  font-family: ${({ theme }) => theme.fonts.big};
  color: ${({ theme }) => theme.colors.secondary};

  @media screen and (max-width: 768px) {
    font-size: 50px;
  }
  @media screen and (max-width: 420px) {
    font-size: 30px;
    margin-bottom: 20px !important;
  }
`
const ListeProjets = styled(motion.div)`
  & .my-masonry-grid {
    display: -webkit-box; /* Not needed if autoprefixing */
    display: -ms-flexbox; /* Not needed if autoprefixing */
    display: flex; /* gutter size offset */
    width: auto;
    margin-top: 40px;
    margin-left: 20px;
    margin-right: 20px;
    overflow: hidden;
    flex-wrap: wrap;

    @media screen and (max-width: 768px) {
      margin-top: 40px;
    }
  }

  &.my-masonry-grid_column {
    padding-left: 30px !important; /* gutter size */
    background-clip: padding-box;
  }
`

const PaginationProjet = styled.div`
  font-family: ${({ theme }) => theme.fonts.normal};
  color: ${({ theme }) => theme.colors.secondary};
  display: flex;
  justify-content: flex-end;
  font-size: 16px;
  padding-right: 20px;
  text-transform: uppercase;

  @media screen and (max-width: 768px) {
    font-size: 14px;
  }

  @media screen and (max-width: 420px) {
    font-size: 12px;
  }
`

const PageItem = styled.a`
  margin-right: 10px;

  opacity: ${({ selected }) => (selected ? 1 : 0.7)};
  position: relative;

  &.active:after {
    content: '';
    position: absolute;
    display: block;
    background-color: currentColor;
    bottom: 0;
    left: 0;
    right: 0;
    height: 2px;
    margin: 0 0 -7px;
    z-index: 20;
    opacity: 1;
  }

  &:not(.active):after {
    content: '';
    position: absolute;
    display: block;
    background-color: currentColor;
    bottom: 0;
    left: 0;
    right: 0;
    height: 2px;
    margin: 0 0 -0.125em;
    z-index: 20;
    opacity: 0;
    transform: translateY(1rem);
    transition-property: transform, opacity;
    transition-duration: 350ms;
    transition-timing-function: cubic-bezier(0.23, 1, 0.32, 1);
    pointer-events: none;
    touch-action: none;
  }

  &:not(.active):hover {
    color: #fff;
  }

  &:not(.active):hover:after {
    opacity: 1;
    transform: translateY(0.3rem);
  }
`

export default Projects

const CardProjet = ({
  projet,
  size,
  delayPerPixel,
  index,
  originIndex,
  originOffset
}) => {
  const delayRef = useRef(0)
  const offset = useRef({ top: 0, left: 0 })
  const ref = useRef()

  // The measurement for all elements happens in the layoutEffect cycle
  // This ensures that when we calculate distance in the effect cycle
  // all elements have already been measured
  useLayoutEffect(() => {
    const element = ref.current
    if (!element) return

    offset.current = {
      top: element.offsetTop,
      left: element.offsetLeft
    }

    if (index === originIndex) {
      originOffset.current = offset.current
    }
  }, [delayPerPixel])

  useEffect(() => {
    const dx = Math.abs(offset.current.left - originOffset.current.left)
    const dy = Math.abs(offset.current.top - originOffset.current.top)
    const d = Math.sqrt(Math.pow(dx, 2) + Math.pow(dy, 2))
    delayRef.current = d * delayPerPixel
  }, [delayPerPixel])

  const handleClickLink = e => {
    e.preventDefault()
    e.stopPropagation()
    if (projet.url !== '') window.open(projet.url, '_blank')
  }

  const itemVariants = {
    hidden: {
      opacity: 0,
      scale: 0.5
    },
    visible: delayRef => ({
      opacity: 1,
      scale: 1,
      transition: { delay: delayRef.current }
    })
  }

  return (
    <CardProjetContainer
      ref={ref}
      size={size}
      variants={itemVariants}
      custom={delayRef}
    >
      <Commentaires>
        <Year>
          {'//'} {projet.year}
        </Year>
        <div className='hideMobile'>
          <Presta>
            {'//'} {projet.presta}
          </Presta>
          <Client>
            {'//'} {projet.client}
          </Client>
        </div>
      </Commentaires>
      <Fonction>
        <Top>
          function <span>{projet.type} () </span>
          <Brace>{'{'}</Brace>
        </Top>
        <Corps>
          {projet.desc.map(item => (
            <Item key={item}>{item}</Item>
          ))}
        </Corps>
        <Bottom>
          <Brace>{'}'}</Brace>
          {projet.url !== '' && (
            <LinkProject onClick={e => handleClickLink(e)}>
              Plus d'infos
            </LinkProject>
          )}
        </Bottom>
      </Fonction>
    </CardProjetContainer>
  )
}

const CardProjetContainer = styled(motion.div)`
  width: ${({ size }) => size};
  background-color: rgba(134, 137, 128, 0.2);
  border-radius: 8px;
  font-family: ${({ theme }) => theme.fonts.mono};
  padding: 10px;
  position: relative;
  z-index: 15;
  transition: All 0.5s ease-in-out;
  margin-bottom: 20px;
  margin-right: 20px !important;
  height: 110px;
  max-height: 110px;
  overflow: hidden;

  :hover {
    padding-top: 20px;
    padding-bottom: 20px;
    height: auto;
    max-height: 300px;
    & .hideMobile {
      display: block;
    }
  }

  @media screen and (max-width: 768px) {
    height: 70px;
    & .hideMobile {
      display: none;
    }
  }
`

const Commentaires = styled.div`
  font-style: italic;
  color: #aeaeae;
  font-size: 14px;
  margin-bottom: 10px;
`
const Year = styled.div``
const Presta = styled.div``
const Client = styled.div``
const Fonction = styled.div`
  color: #aeaeae;
  font-size: 16px;
  font-weight: bold;

  & span {
    color: ${({ theme }) => theme.colors.optional};
    font-weight: normal;
  }
`
const Brace = styled.span`
  color: #ea8566 !important;
`
const Top = styled.div``
const Corps = styled.ul`
  color: #aeaeae;
  margin: 5px 0 !important;
`
const Item = styled.li`
  padding-left: 20px;
  font-size: 14px;
  font-weight: normal;
`
const Bottom = styled.div``

const LinkProject = styled.div`
  color: ${({ theme }) => theme.colors.optional};
  text-decoration: underline;
  font-size: 12px;
  text-align: right;
`
