import styled from 'styled-components/macro'

const Exp = () => {
  const exps = [
    {
      year: '2009',
      content:
        'CDI en Alternance pour la société VIDEO PUB (Création de contenu SEO)'
    },
    { year: '2010', content: 'CDI en Alternance pour l’agence WEB Maespirit' },
    {
      year: '2012',
      content: 'CDI Développeur WEB Junior pour l’agence WEB Maespirit'
    },
    {
      year: '2015',
      content: 'CDI Développeur WEB Senior pour l’agence WEB Maespirit'
    },
    { year: '2018', content: 'Freelance' },
    { year: '2020', content: '' }
  ]
  return (
    <ExpContainer id='experiences'>
      <ColsTitle>
        <TitleContainer>
          <CircleNumber>11</CircleNumber>
          <TextContainer>
            <Years>Années</Years>
            <Experiences>D'Experiences</Experiences>
          </TextContainer>
        </TitleContainer>
      </ColsTitle>
      <StepperContainer>
        <ChronoVertical chronologie={exps} />
      </StepperContainer>
    </ExpContainer>
  )
}

const ExpContainer = styled.section`
  display: flex;
  max-width: 1400px;
  width: 100%;
  margin: 0 auto;
  z-index: 100;
  height: 95vh;

  @media screen and (max-width: 1024px) {
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  @media screen and (max-width: 420px) {
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    padding-left: 20px;
  }
`

const ColsTitle = styled.div`
  display: flex;
  width: 50%;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  @media screen and (max-width: 420px) {
    width: 100%;
    margin-top: 20px;
  }

  @media screen and (max-height: 6px) {
    display: none;
  }
`
const TitleContainer = styled.div`
  display: flex;
  align-items: center;
`
const CircleNumber = styled.div`
  width: 130px;
  height: 130px;
  border-radius: 100%;
  background-color: ${({ theme }) => theme.colors.optional};
  font-family: ${({ theme }) => theme.fonts.big};
  color: ${({ theme }) => theme.colors.primary};
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 108px;
  margin-right: 10px;

  @media screen and (max-width: 768px) {
    width: 90px;
    height: 90px;
    font-size: 80px;
  }

  @media screen and (max-width: 420px) {
    width: 40px;
    height: 40px;
    font-size: 25px;
  }
`
const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
  text-transform: uppercase;
  justify-content: flex-end;
  line-height: 61px;
  margin-top: 25px;

  @media screen and (max-width: 768px) {
    line-height: 38px;
  }

  @media screen and (max-width: 420px) {
    line-height: 25px;
  }
`
const Years = styled.div`
  font-family: ${({ theme }) => theme.fonts.normal};
  color: ${({ theme }) => theme.colors.secondary};
  font-size: 80px;
  font-weight: bold;
  letter-spacing: -6px;

  @media screen and (max-width: 768px) {
    font-size: 60px;
  }

  @media screen and (max-width: 420px) {
    font-size: 30px;
  }
`
const Experiences = styled.div`
  font-family: ${({ theme }) => theme.fonts.big};
  color: ${({ theme }) => theme.colors.secondary};
  font-size: 50px;
  @media screen and (max-width: 768px) {
    font-size: 30px;
  }
`
const StepperContainer = styled.div`
  margin-top: 60px;
  width: 50%;

  @media screen and (max-width: 768px) {
    margin-top: 30px;
  }

  @media screen and (max-width: 420px) {
    width: 90%;
  }
`

export default Exp

const ChronoVertical = ({ chronologie }) => {
  return (
    <ChronoContainer>
      {chronologie &&
        chronologie.map((item, index) => (
          <Item key={item.year}>
            <Circle>{item.year}</Circle>
            {item.content !== '' ? <Content>{item.content}</Content> : null}
            {index < chronologie.length - 1 ? <Line /> : null}
          </Item>
        ))}
    </ChronoContainer>
  )
}

const ChronoContainer = styled.div`
  display: flex;
  flex-direction: column;
`
const Item = styled.div``
const Circle = styled.div`
  border-radius: 80%;
  width: 50px;
  height: 50px;
  background-color: ${({ theme }) => theme.colors.optional};
  color: #ffff;
  font-size: 12px;
  font-family: ${({ theme }) => theme.fonts.normal};
  display: flex;
  justify-content: center;
  align-items: center;

  @media screen and (max-width: 420px) {
    width: 40px;
    height: 40px;
    font-size: 10px;
  }

  @media screen and (max-width: 320px) {
    width: 35px;
    height: 35px;
    font-size: 9px;
  }
`
const Content = styled.div`
  border-left: 1px solid ${({ theme }) => theme.colors.secondary};
  padding-left: 20px;
  padding-top: 20px;
  font-family: ${({ theme }) => theme.fonts.normal};
  font-size: 14px;
  color: ${({ theme }) => theme.colors.secondary};
  margin: 10px 0 0 25px;

  @media screen and (max-width: 420px) {
    font-size: 10px;
    padding-top: 10px;
    margin: 10px 0 0 20px;
  }

  @media screen and (max-width: 320px) {
    font-size: 10px;
    padding-top: 5px;
    margin: 5px 0 0 17px;
  }
`
const Line = styled.div`
  border-left: 1px solid ${({ theme }) => theme.colors.secondary};
  min-height: 20px;
  margin-left: 25px;
  margin-bottom: 10px;

  @media screen and (max-width: 420px) {
    margin-left: 20px;
    min-height: 10px;
  }

  @media screen and (max-width: 320px) {
    margin-left: 17px;
    min-height: 5px;
  }
`
