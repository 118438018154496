import React from 'react'
import ReactScrollWheelHandler from 'react-scroll-wheel-handler'
import useNavigation from '../Hooks/useNavigation'
import styled from 'styled-components/macro'

const DecoratorForNavigation = ({ children }) => {
  const { targetPrev, targetNext, scrollTo } = useNavigation()
  return (
    <Container>
      <ReactScrollWheelHandler
        upHandler={e => scrollTo(e, targetPrev)}
        downHandler={e => scrollTo(e, targetNext)}
        preventScroll
        disableSwipe
      >
        {children}
      </ReactScrollWheelHandler>
    </Container>
  )
}

const Container = styled.div`
  z-index: 150;
  position: relative;
`

export default DecoratorForNavigation
