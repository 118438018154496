import React, { useEffect, useState } from 'react'
import styled from 'styled-components/macro'
import data from '../skills.json'

const Skills = () => {
  const [catSelected, setCatSelected] = useState(0)
  const [isSelecting, setIsSelecting] = useState(false)
  const [showSkillsSelected, setShowSkillsSelected] = useState(0)

  const changeCat = idCat => {
    setCatSelected(idCat)
    setIsSelecting(true)
    fakeTimeSelecting(500).then(() => setShowSkillsSelected(idCat))
  }

  const fakeTimeSelecting = time => {
    return new Promise(resolve => {
      window.setTimeout(function () {
        resolve(setIsSelecting(false))
      }, time)
    })
  }

  return (
    <SkillsContainer id='skills'>
      <SkillsTitle>Compétences</SkillsTitle>
      <SkillsContentContainer>
        <SkillsCatContainer>
          <SkillsListCat>
            <SkillsCat
              isSelected={catSelected === 0}
              onClick={() => changeCat(0)}
            >
              Design/UX
            </SkillsCat>
            <SkillsCat
              isSelected={catSelected === 1}
              onClick={() => changeCat(1)}
            >
              Web design
            </SkillsCat>
            <SkillsCat
              isSelected={catSelected === 2}
              onClick={() => changeCat(2)}
            >
              Web development
            </SkillsCat>
          </SkillsListCat>
        </SkillsCatContainer>
        <SkillsListContainer isSelecting={isSelecting}>
          <SkillsList isSelected={showSkillsSelected === 0}>
            {data.skills &&
              data.skills.design.map(skill => (
                <SkillsListItem
                  key={skill.title}
                  isSelected={showSkillsSelected === 0}
                  name={skill.title}
                  level={skill.level}
                />
              ))}
          </SkillsList>
          <SkillsList isSelected={showSkillsSelected === 1}>
            {data.skills &&
              data.skills.front.map(skill => (
                <SkillsListItem
                  key={skill.title}
                  isSelected={showSkillsSelected === 1}
                  name={skill.title}
                  level={skill.level}
                />
              ))}
          </SkillsList>
          <SkillsList isSelected={showSkillsSelected === 2}>
            {data.skills &&
              data.skills.dev.map(skill => (
                <SkillsListItem
                  key={skill.title}
                  isSelected={showSkillsSelected === 2}
                  name={skill.title}
                  level={skill.level}
                />
              ))}
          </SkillsList>
        </SkillsListContainer>
      </SkillsContentContainer>
    </SkillsContainer>
  )
}

const SkillsContainer = styled.section`
  height: 100vh;
  width: 100%;
  z-index: 150;
  color: ${({ theme }) => theme.colors.secondary};
  max-width: 1400px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
`

const SkillsTitle = styled.h2`
  text-align: right;
  font-family: ${({ theme }) => theme.fonts.big};
  font-size: 60px;
  padding-right: 20px;

  @media screen and (max-width: 768px) {
    font-size: 50px;
  }

  @media screen and (max-width: 420px) {
    font-size: 30px;
  }
`

const SkillsContentContainer = styled.div`
  display: flex;
  margin: auto 0;
  height: 300px;

  @media screen and (max-width: 768px) {
    flex-direction: column;
    height: 800px;
  }

  @media screen and (max-width: 420px) {
    height: 500px;
  }
`
const SkillsCatContainer = styled.div`
  width: 50%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  @media screen and (max-width: 768px) {
    width: 100%;
    justify-content: center;
  }
`
const SkillsListCat = styled.div`
  display: flex;
  justify-content: center;
  align-items: flex-end;
  flex-direction: column;

  @media screen and (max-width: 768px) {
    flex-direction: row;
    align-items: center;
    justify-content: space-around;
    width: 100%;
  }
`
const SkillsCat = styled.a`
  font-family: ${({ theme }) => theme.fonts.normal};
  font-size: ${({ isSelected }) => (isSelected ? '50px' : '20px')};
  letter-spacing: ${({ isSelected }) => (isSelected ? '-4px' : '0px')};
  line-height: 60px;

  z-index: 160;
  padding-right: ${({ isSelected }) => (isSelected ? '30px' : '60px')};
  color: ${({ theme, isSelected }) =>
    isSelected ? theme.colors.optional : theme.colors.secondary};
  text-align: right;

  transition: All 0.3s ease-in-out;

  &:hover {
    color: ${({ theme }) => theme.colors.optional};
    padding-right: 30px;

    @media screen and (max-width: 420px) {
      padding-right: 0px;
    }
  }

  @media screen and (max-width: 768px) {
    font-size: ${({ isSelected }) => (isSelected ? '30px' : '16px')};
    letter-spacing: ${({ isSelected }) => (isSelected ? '0px' : '0px')};
  }
  @media screen and (max-width: 420px) {
    font-size: ${({ isSelected }) => (isSelected ? '12px' : '14px')};
    line-height: 20px;
    padding-right: ${({ isSelected }) => (isSelected ? '0px' : '0px')};
  }
`
const SkillsListContainer = styled.div`
  width: 50%;
  border-left: 2px solid ${({ theme }) => theme.colors.secondary};
  overflow: hidden;
  height: ${({ isSelecting }) => (isSelecting ? '0%' : '100%')};
  transition: All 0.5s ease-in-out;

  @media screen and (max-width: 768px) {
    width: 100%;
    border-left: 0;
  }
`
const SkillsList = styled.div`
  padding: 20px 40px;
  display: ${({ isSelected }) => (isSelected ? 'block' : 'none')};

  @media screen and (max-width: 768px) {
    padding: 60px 40px;
  }
`

const SkillsListItem = ({ name, level, isSelected }) => {
  const [stateLevel, setStateLevel] = useState(0)

  useEffect(() => {
    setStateLevel(0)

    const animateItem = () => {
      let start = 0
      const interval = setInterval(function () {
        start += 1
        setStateLevel(start)
        if (start === level) clearInterval(interval)
      }, 10)
    }

    function randomIntFromInterval (min, max) {
      // min and max included
      return Math.floor(Math.random() * (max - min + 1) + min)
    }

    const Delay = randomIntFromInterval(200, 1000)

    const timeout = setTimeout(function () {
      animateItem()
    }, Delay)

    return () => {
      clearTimeout(timeout)
    }
  }, [level, isSelected])
  return (
    <ItemContainer>
      <ItemName>{name}</ItemName>
      <ItemBarAndLevel>
        <ItemBarContainer>
          <ItemBar>
            <ItemBarLevel level={stateLevel} />
          </ItemBar>
        </ItemBarContainer>

        <ItemLevel>{`${stateLevel}%`}</ItemLevel>
      </ItemBarAndLevel>
    </ItemContainer>
  )
}

const ItemContainer = styled.div`
  display: flex;

  color: ${({ theme }) => theme.colors.optional};
  font-family: ${({ theme }) => theme.fonts.normal};
  font-size: 18px;
  align-items: center;
  width: 100%;
  margin-bottom: 15px;

  @media screen and (max-width: 420px) {
    font-size: 12px;
  }
`
const ItemName = styled.div`
  width: max-content;
  min-width: 180px;
  padding-right: 5px;
  overflow: hidden;
  white-space: nowrap;
  @media screen and (max-width: 420px) {
    min-width: 127px;
  }
`
const ItemBarAndLevel = styled.div`
  display: flex;
  margin-left: 10px;
  width: 100%;
  align-items: center;
`
const ItemBarContainer = styled.div`
  width: 100%;
  height: 6px;
`
const ItemBar = styled.div`
  height: 100%;
  position: relative;
  background-color: ${({ theme }) => theme.colors.secondary};
`
const ItemBarLevel = styled.div`
  background-color: #f17048 !important;
  height: 100%;
  width: ${({ level }) => `${level}%`};
  position: absolute;
`
const ItemLevel = styled.span`
  margin-left: 10px;
  width: 80px;
  color: ${({ theme }) => theme.colors.secondary};
  @media screen and (max-width: 420px) {
    margin-left: 40px;
  }
`

export default Skills
